import React, { FC } from 'react';
import MainTitle from '../../../common/MainTitle';
import SectionTitle from '../../../common/SectionTitle';
import './SectionHeader.scss';
import { ISectionHeader } from '../../../../types/types';

const SectionHeader: FC<ISectionHeader> = (props) => {
    return (
        <div data-testid="section-header" className="section-header__wrapper">
            <MainTitle
                subtypeB="bold"
                subtype="small"
                type={props.typeValue}
                subtitleF={props.subtitleTextF}
                subtitleS={props.subtitleTextS}
                subtitleB={props.subtitleTextB}
                title={props.titleText}
            />
            <SectionTitle type="primary" title={props.secondaryTitleText} />
        </div>
    );
};

export default SectionHeader;
