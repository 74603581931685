import React, { FC } from 'react';
import './TextDescription.scss';
import { ITextDescription } from '../../../types/types';

const TextDescription: FC<ITextDescription> = (props) => {
    return (
        <p
            data-testid="text-description"
            id={props.id}
            className={`text-description text-description--${props.type} text-description--${props.subtype}`}
        >
            {props.children}
        </p>
    );
};

export default TextDescription;
