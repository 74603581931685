import React, { FC, useState } from 'react';
import VideoItem from '../../components/pagesComponents/HowWeWork/VideoItem';
import SectionHeader from '../../components/pagesComponents/HowWeWork/SectionHeader';
import Layouts from '../../components/layout';
import triangleRight from '../../assets/img/triangle-right.svg';
import bike from '../../assets/img/howWeWork/bike.svg';
import protection from '../../assets/img/howWeWork/protection.svg';
import './howWeWork.scss';
import TextItem from '../../components/pagesComponents/HowWeWork/TextItem';
import TextDescription from '../../components/common/TextDescription';
import VideoPlayer from '../../components/common/VideoPlayer';
import FixedButton from '../../components/common/FixedButton';
import SEO from '../../components/SEO';

const howWeWork: FC = () => {
    const [toggleVideo, setToggleVideo] = useState<boolean>(false);
    const URL: string =
        'https://www.youtube.com/watch?v=YoeDuQSB8ZI&list=PLt_GKfBvBhLd59QVK527ofH9zbdwqVtwI&index=4&ab_channel=BrightSpaceArchitects';

    const openVideo = (): void => setToggleVideo(true);
    return (
        <Layouts>
            <SEO
                pageTitle="Blackbird Lab - How We Work"
                pageDescription="Blackbird Lab is a software development company that provides effective solutions to clients worldwide. Since 2018, we have been on a mission to deliver quality products..."
                pageImage="https://blackbird-image-cdn.s3.eu-central-1.amazonaws.com/how-we-work/how-we-work.png"
            />
            <section className="how-we-work">
                <SectionHeader
                    subtitleTextF="Create."
                    subtitleTextB="Protect."
                    subtitleTextS="Deliver."
                    titleText="How We Work"
                    large="large"
                    typeValue="section"
                />
                <TextDescription type="fs16">
                    Blackbird has a solid track record of collaborating with companies as their
                    co-innovation partner. Our expertise in product and platform engineering has
                    helped businesses overcome challenges, optimize their development efforts, and
                    achieve success. We take ownership and responsibility for a range of processes
                    and functions, allowing your in-house team to focus on its core strengths. Our
                    seamless integration ensures enhanced efficiency and accelerated project
                    timelines. We understand the significance of trust in a partnership; our team is
                    deeply committed to delivering exceptional results.
                </TextDescription>
                {/*<div className="how-we-work__overflow">*/}
                {/*    <div className="how-we-work__video">*/}
                {/*        <div className="how-we-work__video-wrapper">*/}
                {/*            <VideoItem*/}
                {/*                visible={false}*/}
                {/*                buttonType="circle"*/}
                {/*                buttonName="Watch now"*/}
                {/*                buttonDesc="Watch now"*/}
                {/*                buttonIcon={triangleRight}*/}
                {/*                num="1"*/}
                {/*                label="Good talk first"*/}
                {/*                size="medium"*/}
                {/*                labelType="medium"*/}
                {/*                position="center"*/}
                {/*                openVideo={openVideo}*/}
                {/*            />*/}
                {/*            <VideoItem*/}
                {/*                visible={false}*/}
                {/*                buttonType="circle"*/}
                {/*                buttonName="Watch now"*/}
                {/*                buttonDesc="Watch now"*/}
                {/*                buttonIcon={triangleRight}*/}
                {/*                num="2"*/}
                {/*                label="Thorough choice"*/}
                {/*                size="medium"*/}
                {/*                labelType="medium"*/}
                {/*                position="center"*/}
                {/*                openVideo={openVideo}*/}
                {/*            />*/}
                {/*            <VideoItem*/}
                {/*                visible={false}*/}
                {/*                buttonType="circle"*/}
                {/*                buttonName="Watch now"*/}
                {/*                buttonDesc="Watch now"*/}
                {/*                buttonIcon={triangleRight}*/}
                {/*                num="3"*/}
                {/*                label="Careful implementation"*/}
                {/*                size="medium"*/}
                {/*                labelType="medium"*/}
                {/*                position="center"*/}
                {/*                openVideo={openVideo}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    {toggleVideo && (*/}
                {/*        <VideoPlayer*/}
                {/*            videoVisibility={toggleVideo}*/}
                {/*            videoURL={URL}*/}
                {/*            setToggleVideo={setToggleVideo}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*</div>*/}
                <TextItem
                    image={bike}
                    itemDesc="You may choose between two engagement models: on-demand team extension or fully managed IT services. Both of them are coming with guaranteed premium perks from Blackbird. Find out which one works for you with our interactive guide."
                    itemTitle="Choose a cooperation model that suits you"
                    link="/how-we-work/engagement-models/"
                    size="small"
                />
                <TextItem
                    image={protection}
                    itemDesc="Being your off-site team means more attention to intellectual property and overall information security. That is why we crafted our own multi-layered intellectual property protection system out of proven industry standards, approaches, government IP policies  and own experience."
                    itemTitle="Enjoy federal-graded intellectual property protection"
                    link="/how-we-work/intellectual-property/"
                    size="small"
                    margin="ml-20"
                />
            </section>
            <FixedButton />
        </Layouts>
    );
};

export default howWeWork;
