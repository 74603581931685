import React, { Fragment } from 'react';
import Header from './common/Header';
import Footer from './common/Footer';

const Layout = ({ children }): JSX.Element => {
    return (
        <Fragment>
            <Header />
            <div>
                <main>{children}</main>
            </div>
            <Footer />
        </Fragment>
    );
};

export default Layout;
