import React, { FC } from 'react';
import SectionTitle from '../SectionTitle';
import { IMainTitle } from '../../../types/types';

const MainTitle: FC<IMainTitle> = (props) => {
    return (
        <div>
            <div className="title__wrapper">
                <SectionTitle type="small" title={props.subtitleF} />
                <SectionTitle type="bold" title={props.subtitleB} />
                <SectionTitle type="small" title={props.subtitleS} />
            </div>
            <h1 data-testid="main-title" className={`title title--${props.type}`}>
                {props.title}
            </h1>
        </div>
    );
};

export default MainTitle;
